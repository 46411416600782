import React from 'react'
import styled from 'styled-components'

function ServiceCard(props) {
    return (
        <Container>
            <img src={props.img}></img>
            <h3>
                {props.title}
            </h3>
            <p>{props.description}</p>

        </Container>
    )
}

export default ServiceCard


const Container = styled.div`
box-sizing: border-box;
padding: 20px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
width: 170px;
cursor: pointer;
align-items: center;
text-align: center;
border-radius: 10px;
margin-right: 20px;
img{
width: 50px;
height: 50px;
border-top-right-radius: 10px;
border-top-left-radius: 10px;
}
h3{
    margin-top: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 12px;
    text-align: center;

    color: #000000;
}
p{
    padding: 7px;
    margin-top: 18px;
    font-size: 19px;
    margin-bottom: 10px;
}

@media (max-width: 600px){

padding: 10px;
width: 160px;
height: 140px;
margin-right: 0px;
margin-top: 20px;
img{
width: 50px;
height: 50px;
border-top-right-radius: 10px;
border-top-left-radius: 10px;
}
h3{
    margin-top: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
   
    color: #000000;
}
p{
    padding: 7px;
    margin-top: 18px;
    font-size: 19px;
    margin-bottom: 10px;
}
}
`