import React from 'react'
import styled from 'styled-components'

function Card(props) {
    return (
        <Container>
            <img src={props.img}></img>
            <h3>
                {props.title}
            </h3>
            <p className='desc'>{props.description}</p>

        </Container>
    )
}

export default Card

const Container = styled.div`
box-sizing: border-box;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
width: 250px;
cursor: pointer;
align-items: center;
text-align: center;
border-radius: 10px;
margin-right: 20px;
img{
width: 100%;
height: 170px;
border-top-right-radius: 10px;
border-top-left-radius: 10px;
}
h3{
    margin-top: 8px;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
  
    color: #000000;
}
.desc{
    padding: 10px;
    margin-top: 5px;
    font-size: 19px;
    margin-bottom: 10px;
    line-height: 25px;
}
@media (max-width: 600px){
margin-bottom: 20px;
margin-right: 5px;
    width: 160px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    border-radius: 10px;

    img{
    width: 100%;
    height: 109px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    }
    h3{
    margin-top: 8px;
    font-weight: 700px;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
 
    color: #000000;
}
.desc{
    padding: 5px;
    margin-top: 6px;
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 18px;
    color: #444;
    }
}
`