import React from 'react'
import styled from 'styled-components'
import startupIndia from '../../../assets/images/startupindia logo.png'
import Dpiit from '../../../assets/images/DPIIT.svg'
import startinUp from '../../../assets/images/startinup.png'

import Twitter from '../../../assets/images/x.com.png'
import Instagram from '../../../assets/images/instagram.png'
import Youtube from '../../../assets/images/youtube.png'
import FaceBook from '../../../assets/images/facebook.png'

function Certificates() {
    return (
        <Container>
            <div className='cerificateText'>

                <li className='title'>CERTIFIED BY</li>
                <h2>|</h2>
                <Logos>
                    <img src={startupIndia} alt='Linkosm startup India logo' className='mainlogo' title='Linkosm startup India logo' loading='lazy' />
                    <img src={Dpiit} alt='Linkosm DPIIT India logo' className='osmlogo' title='Linkosm DPIIT India logo' loading='lazy' />
                    <img src={startinUp} alt='Linkosm startinUp India logo' className='mainlogo' title='Linkosm startinUp India logo' loading='lazy' />

                </Logos>

            </div>

            <div className='social'>

                <p className='followus'>Follow Us</p>
                <SocialLink>

                    <a target='blank' href=" https://twitter.com/linkosm_in"><li> <img src={Twitter} alt='Linkosm Twitter Logo' title='Linkosm Twitter Logo' /></li></a>
                    <a target='blank' href="https://www.instagram.com/linkosm_in/"><li> <img src={Instagram} alt='Linkosm Instagram Logo' title='Linkosm Instagram Logo' /></li></a>
                    <a target='blank' href="https://www.youtube.com/@linkosm"> <li> <img src={Youtube} alt='Linkosm YouTube Logo' title='Linkosm YouTube Logo' /></li></a>

                    <a target='blank' href="https://www.facebook.com/linkosm"> <li> <img src={FaceBook} alt='Linkosm YouTube Logo' title='Linkosm YouTube Logo' /></li></a>
                </SocialLink>

            </div>
        </Container>
    )
}

export default Certificates
const SocialLink = styled.div`
padding-left:10px;
display:flex;
flex-wrap:no-wrap;
align-items:start;
img{
    width:25px;
    height:25px;
   padding:0px;
   cursor: pointer;
}
li{
    padding:0px 0px;
    font-size:13px;
    margin-right:10px;
}
@media screen and (max-width: 768px){
    padding-left:20px;
    margin-top:10px;
}
`
const Container = styled.div`
// border-top:1px solid #ccc;
padding:20px 40px;
display:flex;
flex-wrap:wrap;
align-items: center;
justify-content: space-between;
background:#F1F4F7;
h2{
    padding-right:20px;
    color:#000;
}
li{
    padding:10px 0px;
    color:#000;
   
}
.followus{
    margin-left: 10px;
}
.cerificateText{
    display:flex;
    flex: wrap;
    align-items:center;
  li{
    font-size:10px;
    margin-right:10px;
  }
}
@media screen and (max-width: 768px){
    padding:5px;
li{
    padding:20px 0px;
    font-size:13px;
    margin-right:10px;
}
.social{
    margin-top:20px;
}

.followus{
    color:#000;
    font-weight:bold;
    margin-left: 0px;
    margin-top: 5px;
}
.cerificateText{
  
  li{
    font-size:10px;
    margin-right:10px;
  }
}
}
`

const Logos = styled.div`
display:flex;
flex-wrap:no-wrap;
align-items: center;
    .mainlogo{
        margin-right:25px;
        width:120px;
        height:30px;
        cursor: pointer;
    }

    .osmlogo{
        margin-right:25px;
        width:143px;
        height:80px;
        cursor: pointer;
    }

    @media screen and (max-width: 768px){
       
        flex-wrap:wrap;
        flex-direction:row;
        .mainlogo{
            margin-right:15px;
            width:90px;
            height:30px;
          
        }
    
        .osmlogo{
            margin-right:5px;
            width:103px;
            height:30px;
            cursor: pointer;
        }
    
    }
`