import React, { useEffect, useState, } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import './App.css';
import HomeScreen from "./Screens/Home";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
function App() {

  window.scrollTo(0, 0);
  const TRACKING_ID = "G-FCW5YQEQFZ";
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    const TRACKING_ID = "G-FCW5YQEQFZ";
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    TagManager.initialize({ gtmId: 'GTM-TSXWRQQF' });
  }, []);


  return (
    <Router>
      {/* <WhatsAppButtom /> */}
      <Routes>
        <Route exact path="/" element={<HomeScreen />} />
      </Routes>

    </Router>
  );
}

export default App;
