import React from 'react'
import Certificates from '../Footers/Footer/Certificates'
import BottomFooter from '../Footers/Footer/BottomFooter'



function FooterScreen() {
    return (
        <>
            <Certificates />
            <BottomFooter />
        </>
    )
}

export default FooterScreen