import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

function BottomFooter() {
    return (
        <Container>
            <Pages>
                <li><Link to="/privacy-policy"> Privacy Policy</Link></li>
                <li>
                    <Link to="/tnc"> Terms of Conditions</Link></li>
                <li>
                    <Link to="/about">About Us</Link></li>
                <li>
                    <Link to="/contact">Help</Link></li>
            </Pages>
            <CopyRight>
                <li><Link to="#">LinkOSM Network Solution LLP</Link>   All rights reserved</li>
            </CopyRight>
        </Container>
    )
}

export default BottomFooter
const Container = styled.div`
padding:20px 40px;
display:flex;
flex-wrap:wrap;

justify-content:space-between;
background:#F1F4F7;
border-top:1px solid #AAA;
@media screen and (max-width: 768px){
    padding:0px;
    align-items:center;
    text-align:center;
}
`
const CopyRight = styled.div`
li{
    color:#000;
    font-size:15px;
    a{
        color:#000;
    }
}
@media screen and (max-width: 768px){
    margin-top:10px;
    margin-bottom:10px;
    margin-left:auto;
    margin-right:auto;
    align-items:center;
    width:100%;
    li{
        color:#000;
        font-size:13px;
        a{
            color:#000;
        }
    }
}

`
const Pages = styled.div`
display:flex;
flex-wrap:wrap;
li{
    font-size:15px;
    padding-right:20px;
    a{
        color:#000;
    }
}

@media screen and (max-width: 768px){
    margin-top:20px;
    margin-left:auto;
    margin-right:auto;
    align-items:center;
    width:100%;
    li{
        font-size:12px;
        padding-right:10px;
        a{
            color:#000;
        }
    }
}
`