import React from 'react'
import styled from 'styled-components'
import logo360 from '../assets/images/LinkOSM 360.png'
function Header() {
    return (
        <Container>
            <img src={logo360}></img>
            <Text>
                <h1>+91 6389837213</h1>
                <p>More Details!</p>
            </Text>
        </Container>
    )
}

export default Header

const Container = styled.div`
display: flex;
padding:40px 10px;

justify-content: space-between;
img{
    width: 350px;
    height: 65px;
    margin-left: 20px;
}

@media (max-width: 600px) {
    padding:5px 0px;

justify-content: space-between;
img{
    width: 150px;
    height: 37px;
    margin-left: 0px;
}
}
`

const Text = styled.div`
padding-right: 10px;
text-align: right;

    h1{
        text-align: center;
        text-indent: 150px;
        letter-spacing: 3px;
        color: #fff;
        line-height: 2.375rem;
    font-size: 1.4861rem;
    }
    p{
        margin-top: 10px;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        line-height: 2.375rem;
    font-size: 1.4861rem;
       
    }

    @media (max-width: 600px){
        padding-right: 10px;
        text-align: right;

    h1{
        text-align: center;
        text-indent: 30px;
        letter-spacing: 2px;
        color: #fff;
        font-size: 15px;
    }
    p{
        margin-top: -12px;
        font-size: 15px;
        color: #fff;
        font-size: 15px;
    }
    }
`