import React from 'react'
import styled from 'styled-components'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
function Services() {
    return (
        <Section>
            <Container>
                <div className='line'><ClearIcon className='Icons' /> <li>E-Commerce Website</li></div>
                <div className='line'><ClearIcon className='Icons' /> <li>SEO</li></div>
                <div className='line'><ClearIcon className='Icons' /> <li>Website Management </li></div>
                <div className='line'><ClearIcon className='Icons' /> <li>Server Cost</li></div>
                <div className='line'><ClearIcon className='Icons' /> <li>Website traffic</li></div>
                <div className='line'><ClearIcon className='Icons' /> <li>Lead CRM</li></div>
                <div className='line'><ClearIcon className='Icons' /> <li>Team Cost</li></div>
                <p className='button'>Save Up-To 250000/Year</p>
            </Container>
            <Container>
                <p className='toptext'>Unlimited Benefits with LinkOSM</p>
                <br />
                <div className='line'><CheckIcon className='Icons2' /> <li>Verified Business Profile</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Manage Unlimited Customers</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Free Server Cost</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Unlimited products (Up to 250)</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Offer Panel</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Digital Stores with URL</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Products with Category</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Dynamic Business Card</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Free Messages</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Market Survey Panel</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Customer Management System</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Digital Business Profile</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Zero Management Cost (0)</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Zero SEO Cost (0)</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Zero Advertising cost (0)</li></div>
                <div className='line'><CheckIcon className='Icons2' /> <li>Zero Digital Marketing cost (0)</li></div>

                <p className='button2'>Only ₹12,000/Y + GST </p>
                <p className='button2'>Get 3 Months Free Consulting</p>
            </Container>
        </Section>
    )
}

export default Services

const Section = styled.div`
display: flex;
flex-wrap: wrap;
/* justify-content: space-evenly; */

`
const Container = styled.div`
padding: 20px 5px;
.line{
    display: flex;
    flex-wrap: nowrap;
    font-size: 20px;
    align-items: center;
    text-align: center;
    font-weight: 500;
    color: #000;
    line-height: 30px;
}
.Icons{
    color: red;
    font-size: 25px;
    font-weight: 800;
}
.Icons2{
    color: green;
    font-size: 25px;
    font-weight: 800;
    background-color: aliceblue;
    border-radius: 50px;
    padding: 3px;
    margin-right: 10px;
}
.button{
    padding: 10px;
    font-size: 25px;
    font-weight: bold;
    background-color: red;
    color: #fff;
    cursor: pointer;
    border-radius: 0px;
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.button2{
    padding: 10px;
    font-size: 25px;
    font-weight: bold;
    background-color: green;
    color: #fff;
    line-height: 30px;
    cursor: pointer;
    border-radius: 0px;
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.toptext{
    font-size: 22px;
    color: green;
    font-weight: 500;
}
`
