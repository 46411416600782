import React from 'react'
import styled from 'styled-components'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
function WhatsAppButtom() {

    const value = 6389837213;

    return (
        <Wrapper>

            <Buttom>
                <li onClick={() => window.open(`tel:${value}`, '_blank')}>Get Free Call Now! <CallIcon className='icons' /></li>
            </Buttom>
        </Wrapper>
    )
}

export default WhatsAppButtom

const Wrapper = styled.section`
display: flex;
justify-content: center;
align-items: center;
position: relative;

`
const Buttom = styled.div`
background-color: #fff;
bottom:0px;
width:100%;
height:90px;
position: fixed;
z-index: 999;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;

.icons{
    font-size: 25px;
    padding-top: 5px;
    
}
li{
    padding: 10px 40px;
    border-radius: 50px;
    background-color: green;
    align-items: center;
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: 600;

}

`