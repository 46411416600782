import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import styled from 'styled-components'
import linkosmBanner from '../assets/images/linkosm-partner.png'
import Card from '../Components/SectionCard/Card'
import img1 from '../assets/images/photography-1.jpg'
import img2 from '../assets/images/photography-2.jpg'
import img3 from '../assets/images/photography-3.jpg'
import ServiceCard from '../Components/SectionCard/ServiceCard'
import s1 from '../assets/images/verified.png'
import s2 from '../assets/images/shopping-online.png'
import s3 from '../assets/images/discount.png'
import s4 from '../assets/images/boutique.png'
import s5 from '../assets/images/cashless-payment.png'
import s6 from '../assets/images/id-card.png'
import s7 from '../assets/images/location.png'
import FooterScreen from './FooterScreen/Index'
import WhatsAppButtom from '../Constant/WhatsAppButtom'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Services from '../Components/Services'

function HomeScreen() {


    const [timeRemaining, setTimeRemaining] = useState(1200); // 1200 seconds = 20 minutes

    useEffect(() => {
        const countdownTimer = setInterval(() => {
            setTimeRemaining(prevTime => prevTime - 1);
        }, 1000);

        // Clean up timer when component unmounts
        return () => clearInterval(countdownTimer);
    }, []);

    // Convert seconds into minutes and seconds
    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    // Format the time to display with leading zeros
    const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    return (
        <>
            <Helmet>
                <title>Partner with LinkOSM.com India - List your Business and Shops on LinkOSM now</title>
                <meta name="description" content="Partner with LinkOSM India - Get 3 Month Free Business Consultation and Support. " />
                <meta name="keywords" content="Partner with LinkOSM India, LinkOSM Partner, Free local business directory, find nearby shops, sellers, Nearby Shops and Business or Sellers,connect with local businesses and shops, LinkOSM is the Local Business Directory India,find a local business directory online, Free Business Listing Directory in India,LinkOSM also Works as a Yellow Pages Directory, Get Free Digital Marketing, Where should I find a local business directory online?, Build a Business Profile, Sell Products And Services in the Local Market, LinkOSM.com, LinkOSM.in, LinkOSM, Online Shopping From Local Market, online shopping india, india shopping online, LinkOSM india, LinkOSM, OSM, buy online, buy mobiles online, buy books online, buy movie dvd's online, ebooks, computers, watches, fashion jewellery, home, kitchen, small appliances, beauty, Sports, Fitness & Outdoors, Local Shops, Local Market, Local Business, Local Market Products, India Local Market, Products from local, By Anything" />
                <meta property="og:title" content="Partner with LinkOSM India - Get 3 Month Free Business Consultation and Support" />
                <link rel="canonical" href="https://partner.linkosm.com/" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/osmapp-f03bd.appspot.com/o/websitebanner%2FGift%20Giving%2C%20Redefined.pnga17f0491-2d25-4e36-b830-e5cf066e4170?alt=media&token=ca0b54c1-8642-4e1b-a464-230c768a61fb" />
                <meta property="og:url" content="https://partner.linkosm.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Partner with LinkOSM India - Get 3 Month Free Business Consultation and Support." />
            </Helmet>

            <WhatsAppButtom />
            <Container>
                <Header />


                <h2 className='top'>Feel Free To Contact Us, We Do Not Charge For The First 3 Months</h2>
                <div className='button'>3 Month Free Consulting and Support</div>
            </Container>
            <Section>

                <h3 className='tophead'>Why LinkOSM Best for Your Business?</h3>
                <p>
                    Managing a business or shop has become challenging due to the increasing presence of online platforms.</p>
                <Services />

                <div className='scroll'>
                    <Card
                        img={img1}
                        title='Sign-up Process'
                        description='Join LinkOSM Now! Sign up in just 1 minute by providing your basic details.'
                    >

                    </Card>
                    <Card
                        img={img2}
                        title='Zero(0) Commission Charges'
                        description=''
                    >

                    </Card>
                    <Card
                        img={img3}
                        title='9AM- 9PM Support Call'
                        description=''
                    >

                    </Card>
                    <Card
                        img={img3}
                        title='24X7 Support On Email'
                        description=''
                    ></Card>
                </div>
            </Section>
            <BenefitsSection>
                <h3 className='bhead'>Tools to accelerate your business growth.</h3>
                <p>Up To 50% Growth</p>
                <div className='scrollTerms'>
                    <ServiceCard
                        img={s1}
                        title='Business Verified Tag'

                    />
                    <ServiceCard
                        img={s2}
                        title='Digital Store'

                    />
                    <ServiceCard
                        img={s3}
                        title='Send Offers'

                    />
                    <ServiceCard
                        img={s4}
                        title='Catalogs'

                    />
                    <ServiceCard
                        img={s5}
                        title='Digital Card'

                    />
                    <ServiceCard
                        img={s6}
                        title='Business Card'

                    />
                </div>
                <br />
                <br />
                <br />
                <h2 className='offers'>🔥 Hurry! Offer Ends Soon! Save Up To 75% - Limited Time Only! <span>{formattedTime}</span></h2>
                <br />
                <div className='button2'> <Link to='https://linkosm.com/business'><li>Get This Offer!</li></Link></div>
                <br />
                <br />
            </BenefitsSection>
            <FooterScreen />
            <br />
            <br />
            <br />
            <br />

        </>
    )
}

export default HomeScreen

const Container = styled.div`

height:100vh;
width:100%;
background:#000;
background-image: url(${linkosmBanner}); 
background-repeat: no-repeat;
background-size: cover;
background-position: center;
.toptext{
   
    padding: 10px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.top{
    margin-top: 12%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #000;
    background-color: antiquewhite;
line-height: 35px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.button{
   
    padding: 10px;
    font-size: 25px;
    font-weight: bold;
    background-color: red;
    color: #fff;
    cursor: pointer;
    border-radius: 0px;
    text-align: center;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 600px){
    height:50vh;
    .toptext{
    padding: 10px;
    margin-bottom: 30px;
    font-size: 17px;
    font-weight: bold;
    color: #fff;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    }
    .top{
        margin-top: 10%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        background-color: white;
        width: 95%;
        line-height: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .button{
   padding: 10px;
   font-size: 16px;
   font-weight: bold;
   background-color: red;
   color: #fff;
   cursor: pointer;
   border-radius: 0px;
   text-align: center;
   width: 95%;
   margin-left: auto;
   margin-right: auto;
 
}
}
`

const Section = styled.div`
padding: 40px;
.tophead{
    font-size: 40px;
    color: #000;
}
p{
    font-size: 20px;
    margin-top: 20px;
}
.scroll{
    margin-top: 30px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
@media (max-width: 600px){

    padding: 10px;
    .tophead{
    font-size: 25px;
    line-height: 30px;
    color: #000;
}
p{
    font-size: 20px;
    margin-top: 20px;
}
}
`

const BenefitsSection = styled.div`
padding: 40px;
.bhead{
    font-size: 40px;
    color: #000;
}
p{
    font-size: 20px;
    margin-top: 20px;
}
.scrollTerms{
    margin-top: 30px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.button2{
   
   padding: 10px;
   font-size: 25px;
   font-weight: bold;
   background-color: green;
   color: #fff;
   cursor: pointer;
   border-radius: 20px;
   text-align: center;
   width: 40%;
   margin-left: auto;
   margin-right: auto;
   a{
    color: #fff;
   }
}

.offers{
line-height: 24px;
   text-align: center;
   width: 40%;
   color: #000;
   margin-left: auto;
   margin-right: auto;

   span{
        font-size: 23px;
        color: red;
   }
}
@media (max-width: 600px){
    padding: 10px;
    .bhead{
        font-size: 25px;
        line-height: 30px;
        color: #000;
    }
    p{
        font-size: 20px;
        margin-top: 20px;
    }
}
    .button2{
   
        padding: 10px;
        font-size: 20px;
        border-radius: 20px;
        text-align: center;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .offers{
width: 90%;
margin-left: auto;
margin-right: auto;
}
`